import axios from 'axios'

/**
 * Mixin manages clearing Local Props bound to chips
 * Once this mixin is included be sure to implement the chipMeta data property
 *
 * This component is intended to be combined with ListSearchHeader
 * Creates Chips from chipMeta
 * Chip Meta EXAMPLE
 * [
 *   {
 *     ?name, # fuzzed from Prop
 *     prop,
 *     type,
 *     ?default # generated from type
 *     ?query # for querify chips for API calls
 *   },
 * ...
 * ]
 */

export default {
  data: () => ({
    fields: [],
    baseUrl: '',
    chipsReflected: false,

    // Chip meta is used to generate a set of chip data from minimal input
    chipMeta: [],

    boolOptions: [
      { text: 'True', value: 'true' },
      { text: 'False', value: 'false' }
    ]
  }),
  methods: {
    // Creates query params for exporting
    getExportQueryParams () {
      let query = { page_size: 0 }
      if (typeof this.querifyChips === 'function') {
        query = { ...query, ...this.querifyChips() }
      }
      if (this.search) query.search = this.search
      return query
    },
    // Executes a page less query and returns the results to a callback
    async executeChipsQuery (callback, options) {
      if (!this.baseUrl) throw Error('baseUrl was not declared')
      const params = this.getExportQueryParams()
      params.fields = this.fields.join(',')

      // short circuit if no params
      if (options && options.hasOwnProperty('chipsRequired')) {
        if (Object.keys(this.querifyChips()).length === 0) return
      }
      return axios.get(this.baseUrl, { params }).then(response => {
        callback(response.data)
      })
    },
    // propagates the props and the default types
    chipPropagation (chipMeta) {
      let data = { chipMeta }
      chipMeta.forEach(base => {
        data[base.prop] = base.type()
      })
      return data
    },
    chipIsArray (chip) {
      return Array.isArray(this[chip.prop])
    },
    // Turns the chip data into a filtered query targeted string
    parameterizeChips () {
      return this.chips.reduce((params, base) => {
        if (!this[base.prop]) return params
        if (!this.chipIsArray(base)) {
          params[base.prop] = this[base.prop]
          return params
        }
        // handle arrays
        if (this[base.prop].length > 0) {
          params[base.prop] = this[base.prop].join(',')
        }
        return params
      }, {})
    },
    reflectQueryToChipProps () {
      this.__chipPropsFromQuery(this.$route.query)
      this.chipsReflected = true
    },
    // Using the query property turns the chips into query compatible object
    querifyChips () {
      const params = {}
      this.chipMeta.forEach(base => {
        if (typeof base.query === 'undefined') return
        if (this.chipIsArray(base)) {
          if (this[base.prop] && this[base.prop].length > 0) {
            params[base.query] = this[base.prop].join(',')
          }
        } else {
          if (this[base.prop]) params[base.query] = this[base.prop]
        }
      })
      return params
    },
    __chipPropsFromQuery (query) {
      this.chips.forEach(base => {
        if (!query[base.prop]) return
        if (this.chipIsArray(base)) {
          this[base.prop] = query[base.prop].split(',')
        } else if (Number === base.type) {
          this[base.prop] = Number(query[base.prop])
        } else {
          this[base.prop] = query[base.prop]
        }
      })
    },
    // reset a chip
    clearChipProp (chip) {
      this[chip.prop] = chip['type']()
    },
    // resets fields to default values
    clearFields () {
      this.search = ''
      this.chips.forEach(this.clearChipProp)
    },
    // mutates the initial chip data
    chipFactory (base) {
      let clone = Object.assign(base)
      if (typeof clone['name'] === 'undefined') clone.name = clone.prop
      clone.value = this[base.prop]
      clone.show = Boolean(this.chipIsArray(base) ? this[base.prop].length : this[base.prop])
      return clone
    },
    // Triggers a router push with the query params in tow
    routePageWithChips (pageName) {
      const params = this.parameterizeChips()
      if (this.search && this.search.length > 0) params.search = this.search
      this.$router.push({ name: pageName, query: params })
    }
  },
  computed: {
    /**
     * @return {any[]}
     */
    chips () {
      return this.chipMeta.map(this.chipFactory)
    }
  }
}
