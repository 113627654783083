/**
 * Manages automatic pagination and hooks in chips if they are included on the component
 *
 * The containing component should include in mounted
 * this.reflectRouteParams()
 * this.executeSearch()
 *
 * Be sure to implement
 *
 * Execute Search
 *
 * in mounted this.reflectRouteParams() && this.executeSearch()
 *
 * PAGINATION LOOP
 * When pagination is changed the current page/chips are sets as a param object
 * then the page is rerouted based on those params
 * then page is routed based on those params and they are extracted from the route
 * those params are then used to query the api
 *
 * this allows for consistent page routing between page navigations and refreshes
 */
import axios from 'axios'
import _ from 'underscore'

export default {
  data: () => ({
    search: '',
    paginationReady: false, // v-if on datatable to prevent premature options rewrites
    totalCount: 0,
    resultList: [],
    pagination: { page: 1 },
    pageName: '',
    fields: [],
    baseUrl: ''
  }),
  methods: {
    // Performs the default execution
    async executeSearch () {
      if (!this.baseUrl) throw Error('baseUrl was not declared')
      const params = this.getQueryParams()
      if (this.fields.length > 0) {
        params.fields = this.fields.join(',')
      }
      axios.get(this.baseUrl, { params }).then(this.setResponseResults)
    },
    // accepts a response object from the api and saves the results
    setResponseResults (response) {
      this.totalCount = response.data.count
      this.resultList = response.data.results
    },
    // Creates query params and gets chip pagination as well
    getQueryParams () {
      let query = { ordering: this.__orderingQuery() }
      if (this.pagination && this.pagination.page) query.page = this.pagination.page
      if (typeof this.querifyChips === 'function') {
        query = { ...query, ...this.querifyChips() }
      }
      if (this.search) query.search = this.search
      return query
    },
    // creates an ordering string for the pagination
    __orderingQuery () {
      if (!this.pagination || !this.pagination.sortBy || !this.pagination.sortDesc) return ''
      return this.pagination.sortBy.map((name, index) => this.pagination.sortDesc[index] ? `-${name}` : name).join(',')
    },
    // Matches component data with query params
    reflectRouteParams () {
      // attempt to reflect chips if it exists
      if (typeof this.reflectQueryToChipProps === 'function') {
        this.reflectQueryToChipProps()
      }
      this.reflectQueryToPagination()
    },
    reflectQueryToPagination () {
      this.__paginationFromQuery(this.$route.query)
    },
    // assigns the pagination object for query params
    __paginationFromQuery (query) {
      if (query.search) this.search = query.search

      if (query.pagination) this.pagination = JSON.parse(query.pagination)
      if (query.page) this.pagination.page = Number(query.page)
      if (query.sortBy) this.pagination.sortBy = query.sortBy.split(',')
      if (query.sortDesc) this.pagination.sortDesc = query.sortDesc.split(',').map(b => b === 'true')
      this.paginationReady = true
    },
    __parameterizePagination () {
      let params = {}
      if (this.pagination) {
        params['page'] = this.pagination.page
        if (this.pagination?.sortBy?.length) params['sortBy'] = this.pagination.sortBy.join(',')
        if (this.pagination?.sortDesc?.length) params['sortDesc'] = this.pagination.sortDesc.join(',')
      }
      return params
    },
    parameterizeSearchField () {
      let params = {}
      if (this.search && this.search.length > 0) params.search = this.search
      return params
    },
    // collects the route parameters before routing
    __collectRouteParams () {
      let params = {}
      params = { ...params, ...this.__parameterizePagination() }
      // check if parameterizeChips exists
      if (typeof this.parameterizeChips === 'function') {
        params = { ...params, ...this.parameterizeChips() }
      }
      if (this.search && this.search.length > 0) params.search = this.search
      return params
    },
    // Updates the component route triggering the search
    routePage (pagination) {
      if (!this.pageName) throw Error('pageName was not declared')
      this.pagination = pagination
      const params = this.__collectRouteParams()

      // ensure page is also not the same should fix the error this time???
      if (params.page) params.page = String(params.page)
      // Matches page if is page one and page not set
      if (!this.$route.query.page && params.page && params.page === '1') this.$route.query.page = '1'
      // break if queries are the same
      if (_.isEqual(params, this.$route.query)) return
      // if (JSON.stringify(params) === JSON.stringify(this.$route.query)) return
      this.$router.push({ name: this.pageName, query: params })
    }
  }
}
