<template>
  <div v-if="pages">
    <v-pagination
      v-model="page"
      :length="pages"
      total-visible="16"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'ResultPagination',
  props: {
    // page
    value: {
      type: Number,
      default: 1
    },
    pages: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      page: this.value
    }
  },
  watch: {
    value (newVal) {
      this.page = newVal
    }
  }
}
</script>
