<!--
The component should make it much easier to build advanced searches with chips
v-model = for the primary search term
chips = array of chip information, optional [{
          name: 'Start Date',
          prop: 'startDate',
          value: this.startDate,
          show: !!this.startDate
        }]
$emit('input') For V Model Binding
$emit('clearChip', chip) For Clearing Chips (chips) # Provided by Chips Mixin @clearChip="clearChipProp"
$emit('clearFields') Clear All Fields (dialog clear) # Provided by Chips Mixin @clearFields="clearFields"
$emit('submitSearch') Trigger a search event (eye glass)
$emit('advancedOpened') Trigger when the filter dialog is opened, use for lazy loading
-->
<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="8"
        md="6"
      >
        <v-row class="d-flex align-center">
          <v-btn
            v-if="showFilterButton"
            class="mr-1"
            icon
            @click="openAdvanced"
          >
            <v-icon>
              mdi-filter-variant
            </v-icon>
          </v-btn>
          <v-text-field
            :value="value"
            label="Search"
            single-line
            clearable
            :hint="hint"
            append-outer-icon="mdi-magnify"
            @change="$emit('input', $event || '')"
            @click:append-outer="$emit('submitSearch')"
            @keydown.enter="$emit('submitSearch')"
          />
        </v-row>
        <slot name="extras" />
      </v-col>
    </v-row>
    <!--Chips section is complete optional-->
    <v-row v-if="chips.length">
      <v-col
        cols="12"
        sm="8"
        md="6"
      >
        <span
          v-for="chip in chips"
          :key="chip.prop"
        >
          <v-chip
            v-if="!!chip.show"
            small
            close
            @click:close="clearChip(chip)"
          >
            {{ chip.name }} {{ chip.value }}
          </v-chip>
        </span>
      </v-col>
    </v-row>
    <v-dialog
      max-width="800px"
      v-model="dialog"
    >
      <v-form @submit.prevent="search">
        <v-card>
          <v-card-title>
            Filters
          </v-card-title>
          <v-card-text>
            <!--Slot for all the filters to be placed-->
            <slot />
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="$emit('clearFields')"
            >
              Clear Filters
            </v-btn>
            <v-spacer />
            <v-btn
              type="submit"
              color="primary"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'SearchFilters',
  props: {
    showFilterButton: {
      type: Boolean,
      default: true
    },
    hint: {
      type: String,
      default: 'Add filters by clicking on the filter button'
    },
    // The basic search text field to be v-modeled
    value: {
      type: String,
      required: true
    },
    // toggleable chips
    chips: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    search () {
      this.dialog = false
      this.$emit('submitSearch')
    },
    // emits and event to let the parent know the dialog is open
    openAdvanced () {
      this.dialog = true
      this.$emit('advancedOpened')
    },
    clearChip (chip) {
      this.$emit('clearChip', chip)
      this.$emit('submitSearch')
    }
  }
}
</script>
